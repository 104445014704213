import React from "react";
import classes from "./ServiceDetailPage.module.css";
import { useTranslation } from "react-i18next";
function EngagementContent() {
  const { t } = useTranslation();
  const Engagement = t("Our Services", { returnObjects: true })

  return (
    <>
      <div className="container py-5">
        <div className={classes.intro}>
          <h4>
            {Engagement.Engagement.text1}
          </h4>
          <p>
            <p>
              {Engagement.Engagement.text}

            </p>
            <p>
              Employee engagement can return dividends and is a key component of
              productivity, profitability and employee retention
            </p>
          </p>
          <h5>
            In DoT consulting, we have a partnership with CustomInsight due to
            its Strict Validity, Reliability and professionalism.
          </h5>
        </div>
      </div>
    </>
  );
}

export default EngagementContent;
