import React, { useEffect, useRef, useState } from "react";
import { useInView } from "framer-motion";

import classes from "./Team.module.css";
import AnimatedTextCharacter from "../../AnimatedTextCharacter";
import TeamCard from "./TeamCard";
import team1 from "../../../assets/team1.jpg";
import team2 from "../../../assets/team2.jpg";
import team3 from "../../../assets/team3.jpg";

const DATA = [
  {
    name: "Alex Smith",
    img: team1,
    designation: "Founder & CEO",
  },
  {
    name: "May Brown",
    img: team2,
    designation: "Creative Leader",
  },
  {
    name: "John Doe",
    img: team3,
    designation: "Sales Manager",
  },
];

function Team() {
  const elementRef = useRef(null);
  const isInView = useInView(elementRef, { once: true });

  return (
    <div className={classes.teamDiv} id="teamSection">
      <h3 ref={elementRef}>
        <AnimatedTextCharacter text="Meet Our Team" isVisible={isInView} />
      </h3>

      <div className={classes.cardsWrapper}>
        {DATA.map((data) => (
          <TeamCard
            img={data.img}
            name={data.name}
            designation={data.designation}
          />
        ))}
      </div>
    </div>
  );
}

export default Team;
