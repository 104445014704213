import React from "react";
import classes from "./Hero.module.css";
import AnimatedTextCharacter from "../../AnimatedTextCharacter";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ServiceCard from "./ServiceCard";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import ConnectWithoutContactIcon from "@mui/icons-material/ConnectWithoutContact";
import Diversity2Icon from "@mui/icons-material/Diversity2";
import ServicesSlider from "./ServicesSlider";
import { useTranslation } from "react-i18next";

// const SERVICES = [
//   {
//     icon: Diversity3Icon,
//     name: "HR Transformation",
//   },
//   {
//     icon: ConnectWithoutContactIcon,
//     name: "Business Excellence",
//   },
//   {
//     icon: LocalLibraryIcon,
//     name: "Talent Attraction & Improvement",
//   },
//   {
//     icon: Diversity2Icon,
//     name: "Engagement",
//   },
// ];

function Hero() {
  const { t } = useTranslation();
  const SERVICES_DATA = t("services", { returnObjects: true });

  return (
    <div className={classes.hero} id="homeSection">
      <div className={classes.heroContent}>
        <h2>
          {/* What keeps you up at night? */}
          <AnimatedTextCharacter
            text="What keeps you up at night?"
            isVisible={true}
          />
        </h2>
        <p>
          Running a business is challenging with fast global growth. limited
          time to prioritize tasks and align team goals adds complexity to
          keeping up with this fast-paced environment.
        </p>
        <h4>
          Our HR services help make it easier
          <ArrowForwardIcon />
        </h4>
      </div>
      {/* <h1>
        Speech and Language Therapy - <span>reimagined</span>
      </h1>
      <p>engaging, cost-effective, flexible, data-driven.</p>
      <button onClick="" className={classes["learn-more-btn"]}>
        Learn more
      </button> */}
      {/* <img className='logo' src={require("../../assets/logo.png")} alt='logo' /> */}
      <div className={classes.servicesCardsDiv}>
        {SERVICES_DATA.map((service) => (
          <ServiceCard icon={service.icon} name={service.title} />
        ))}
      </div>

      <div className={classes.responsiveServicesCardsDiv}>
        <ServicesSlider services={SERVICES_DATA} />
      </div>
    </div>
  );
}

export default Hero;
