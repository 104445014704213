import React from "react";
import classes from "./AboutUs.module.css";
import Why from "./Why";
import VisionMission from "./VisionMission";
import Values from "./Values";

function AboutUs() {
  return (
    <div className={classes.aboutDiv} id="aboutSection">
      <h6>About Us</h6>
      <Why />
      <VisionMission />
      <Values />
    </div>
  );
}

export default AboutUs;
