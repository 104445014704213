import React from "react";
import classes from "./TeamCard.module.css";
import { Facebook, Instagram, Twitter } from "@mui/icons-material";

function TeamCard({ img, name, designation }) {
  return (
    <div className={classes.teamCard}>
      <div className={classes.profileImgDiv}>
        <img src={img} alt="profile-pic" />
      </div>

      <div className={classes.details}>
        <h4>{name}</h4>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas et
          tincidunt velit. Sed ac venenatis enim, et placerat diam. Quisque
          convallis turpis sit amet aliquam lacinia. Duis viverra laoreet orci,
          id semper justo fermentum lobortis.
        </p>
        <h6>{designation}</h6>
      </div>

      <div className={classes.social}>
        <Facebook />
        <Twitter />
        <Instagram />
      </div>
    </div>
  );
}

export default TeamCard;
