import React from "react";
import Card from "./Card";
import classes from "./VisionMission.module.css";
import vision from "../../../assets/icons/vision.svg";
import mission from "../../../assets/icons/mission.svg";
import { useAnimation, useInView } from "framer-motion";
import { useRef } from "react";
import { useEffect } from "react";

import { useTranslation } from "react-i18next";

function VisionMission() {
  const { t } = useTranslation();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      //fire the animation
      mainControls.start("visible");
    }
  }, [isInView]);

  const visionVariant = {
    hidden: {
      opacity: 0,
      x: -100,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.6,
        // delay: 0.2,
      },
    },
  };

  const missionVariant = {
    hidden: {
      opacity: 0,
      x: -200,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1,
        // delay: 0.4,
        // ease: "easeIn",
      },
    },
  };

  return (
    <div className={classes.visionMissionDiv} ref={ref}>
      <Card
        initial="hidden"
        animate={mainControls}
        variants={visionVariant}
        type="vision"
        duration={0.5}
        icon={vision}
        heading={t("vision.title")}
        content={t("vision.text")}
      />
      <Card
        initial="hidden"
        animate={mainControls}
        variants={missionVariant}
        type="vision"
        duration={1}
        icon={mission}
        heading={t("mission.title")}
        content={t("mission.text")}
      />
    </div>
  );
}

export default VisionMission;
