import React from "react";
import classes from "./Services.module.css";
import ServiceCard from "./ServiceCard";
import hr from "../../../assets/hr.jpeg";
import learning from "../../../assets/learning.jpg";
import talent from "../../../assets/talent.jpg";
import engagement from "../../../assets/engagement.png";
import { motion, useAnimation, useInView } from "framer-motion";
import { useRef } from "react";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
// import { useInView } from "react-intersection-observer";

// const SERVICES = [
//   {
//     img: hr,
//     path: "hc-transformation-management",
//     name: "HC Transformation Management",
//     desc: "In response to the demand for HR to play a more strategic role, transformation can elevate HR from an administrative and task-oriented function to a strategic business partner that aligns talent management and workforce development with the company’s goals. HC Transformation is the process of aligning HR practices with strategic business objectives, enhancing employee experience, improving efficiency, and adopting data-driven decision-making",
//   },

//   {
//     img: talent,
//     path: "talent-management",
//     name: "Talent Management",
//     desc: "The systematic and strategic approach of effectively acquiring suitable talent and fostering their development towards their fullest potential, while aligning with the goals of the organization. Our aim is not to be just another supplier but rather a strategic partner in your success and an extension of your talent team.",
//   },
//   {
//     img: engagement,
//     path: "employees-engagement",
//     name: "Employees Engagement",
//     desc: "Engagement is the behaviour that results from conditions that make an employee feel fulfilled, valued, satisfied and invested in the organization’s prosperity and actively work to meet strategic goals. Employee engagement can return dividends and is a key component of productivity, profitability and employee retention",
//   },
//   {
//     img: learning,
//     path: "organisational-transformation",
//     name: "Organizational Transformation",
//     desc: "It's focused on incremental improvements and is designed to enhance the organization’s effectiveness and efficiency. It involves restructuring the organization’s strategy, processes, governance and culture to adapt to new market conditions, technological advancements, or other external factors. It’s requiring organizations to look critically at their operations and how they manage employees.",
//   },
// ];

function Services() {
  // const [ref, inView] = useInView({
  //   triggerOnce: true,
  //   threshold: 0.5,
  // });
  const { t } = useTranslation()
  const SERVICES = t("OurServices", { returnObjects: true })
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.3 });

  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      //fire the animation
      mainControls.start("visible");
    }
  }, [isInView]);

  // const variants = {
  //   hidden: {
  //     opacity: 0,
  //     y: 75,
  //   },
  //   visible: {
  //     opacity: 1,
  //     y: 0,
  //     transition: {
  //       duration: 0.8,
  //       delay: 0.4,
  //       // ease: "easeIn",
  //     },
  //   },
  // };

  return (
    <div className={classes.servicesDiv} id="servicesSection">
      <h3>Our Services</h3>
      <div  className={classes.cardsContainer} ref={ref}>
        {SERVICES.map((service, index) => (
          <motion.div
            key={index}
            initial="hidden"
            animate={mainControls}
            variants={{
              hidden: {
                opacity: 0,
                y: 75,
              },
              visible: {
                opacity: 1,
                y: 0,
                transition: {
                  duration: 0.8,
                  delay: (index + 1) * 0.05,
                },
              },
            }}
          >
            <ServiceCard 
              key={index}
              name={service.title}
              img={service.Image.Img}
              desc={service.text}
              path={service.path}

            // duration={(index + 1) * 0.3}
            />
          </motion.div>
        ))}
      </div>
    </div>
  );
}

export default Services;
