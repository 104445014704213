import React from "react";
import ReactVisibilitySensor from "react-visibility-sensor";
import CountUp from "react-countup";
import classes from "./Counter.module.css";

function Counter() {
  return (
    <div className={classes.counterDiv}>
      <div className={classes.header}>
        <h4>Strength in numbers</h4>
        <div class="bottom-arrow"></div>
      </div>
      <div className={classes.reachPoints}>
        <ReactVisibilitySensor>
          {({ isVisible }) => (
            <>
              <div className={classes.pointDiv}>
                <div className={classes.stats}>
                  <h3>
                    {isVisible ? (
                      <CountUp
                        enableScrollSpy={true}
                        scrollSpyDelay={1000}
                        duration={5}
                        end={25}
                      />
                    ) : (
                      "25"
                    )}
                    +
                  </h3>
                </div>
                <h4>Businesses</h4>
              </div>

              <div className={classes.pointDiv}>
                <div className={classes.stats}>
                  <h3>
                    {isVisible ? (
                      <CountUp
                        enableScrollSpy={true}
                        scrollSpyDelay={1000}
                        duration={5}
                        end={200}
                      />
                    ) : (
                      "200"
                    )}
                    +
                  </h3>
                </div>
                <h4>Consulting</h4>
              </div>

              <div className={classes.pointDiv}>
                <div className={classes.stats}>
                  <h3>
                    {isVisible ? (
                      <CountUp
                        enableScrollSpy={true}
                        scrollSpyDelay={1000}
                        duration={5}
                        end={8}
                      />
                    ) : (
                      "8"
                    )}
                    K+
                  </h3>
                </div>
                <h4>Portfolios</h4>
              </div>

              <div className={classes.pointDiv}>
                <div className={classes.stats}>
                  <h3>
                    $
                    {isVisible ? (
                      <CountUp
                        enableScrollSpy={true}
                        scrollSpyDelay={1000}
                        duration={5}
                        end={300}
                      />
                    ) : (
                      "300"
                    )}
                    M
                  </h3>
                </div>
                <h4>Payroll</h4>
              </div>
            </>
          )}
        </ReactVisibilitySensor>
      </div>
    </div>
  );
}

export default Counter;
