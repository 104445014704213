import React from "react";
import { motion } from "framer-motion";
import { isVisible } from "@testing-library/user-event/dist/utils";

function AnimatedTextCharacter({ text, isVisible }) {
  // splitting text into letters

  const letters = Array.from(text);

  // Variants for Container
  const container = {
    hidden: { opacity: 0 },
    visible: (i = 1) => ({
      opacity: 1,
      transition: { staggerChildren: 0.05, delayChildren: 0.1 * i },
    }),
  };

  // Variants for each letter
  const child = {
    visible: {
      opacity: 1,
      x: 0,
      y: 0,
      transition: {
        type: "spring",
        damping: 12,
        stiffness: 100,
      },
    },
    hidden: {
      opacity: 0,
      x: -20,
      y: 10,
      transition: {
        type: "spring",
        damping: 12,
        stiffness: 100,
      },
    },
  };

  return (
    <motion.span
      // style={{ fontSize: "2.6rem", display: "flex", flexWrap: "wrap" }}
      variants={container}
      initial="hidden"
      // animate="visible"
      animate={isVisible ? "visible" : "hidden"}

      // animate={isVisible ? "visible" : "hidden"}
    >
      {/* {text.map((letter, index) => (
        <motion.span variants={child} key={index}>
          {letter === " " ? "\u00A0" : letter}
        </motion.span>
      ))} */}

      {text.split(" ").map((word) => (
        <span style={{ display: "inline-block" }}>
          {word.split("").map((char) => (
            <motion.span variants={child}>{char}</motion.span>
          ))}
          <span>&nbsp;</span>
        </span>
      ))}
    </motion.span>
  );
}

export default AnimatedTextCharacter;
