import React from "react";
import classes from "./ServiceCard.module.css";
import image from "../../../assets/clients.jpg";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";
import { useNavigate } from "react-router-dom";
import cookies from "js-cookie";

function ServiceCard({ name, img, duration, desc, path }) {
  const currentLanguageCode = cookies.get("i18next") || "en"; //"i18next" is the cookie name which has en/ar/fr value

  const navigate = useNavigate();

  function handleServiceNavigation() {
    navigate(`/${currentLanguageCode}/services/${path}`);
  }

  if(currentLanguageCode === "ar"){
    return (
      // <AnimationOnScroll
      //   duration={duration}
      //   // animateIn="animate__bounceInLeft"
      //   animateIn="animate__fadeInLeft"
  
      //   // animateOut="animate__fadeOutRightBig"
      // >
      <div className={classes.cardService} onClick={handleServiceNavigation}>
        <div className={classes.imageDiv}>
          <img src={img} alt="services" />
        </div>
        <div className={classes.detailDiv} style={{
          textAlign:"right"
        }}>
          <h5>{name}</h5>
          <p>
            {`${desc.substring(0, 320)}...`}
            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vehicula
            efficitur lorem id fringilla. Mauris lobortis ornare urna ut
            vulputate. */}
          </p>
        </div>
      </div>
      // </AnimationOnScroll>
    );
  }else{
    return (
      // <AnimationOnScroll
      //   duration={duration}
      //   // animateIn="animate__bounceInLeft"
      //   animateIn="animate__fadeInLeft"
  
      //   // animateOut="animate__fadeOutRightBig"
      // >
      <div className={classes.cardService} onClick={handleServiceNavigation}>
        <div className={classes.imageDiv}>
          <img src={img} alt="services" />
        </div>
        <div className={classes.detailDiv} >
          <h5>{name}</h5>
          <p>
            {`${desc.substring(0, 320)}...`}
            {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc vehicula
            efficitur lorem id fringilla. Mauris lobortis ornare urna ut
            vulputate. */}
          </p>
        </div>
      </div>
      // </AnimationOnScroll>
    );
  }
}

export default ServiceCard;
