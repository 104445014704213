import React from "react";
import icon from "../../../assets/icons/value2.svg";
import classes from "./ServiceCard.module.css";

function ServiceCard({ name }) {
  return (
    <div className={classes.serviceCard}>
      <img src={icon} alt="service-icon" />
      {/* {icon} */}
      <h4>{name}</h4>
    </div>
  );
}

export default ServiceCard;
