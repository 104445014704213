import React from "react";
import { DashboardCustomizeOutlined } from "@mui/icons-material";
import classes from "./Card.module.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";
import { motion } from "framer-motion";

function Card({
  icon,
  heading,
  content,
  type,
  duration,
  initial,
  animate,
  variants,
}) {
  return (
    // <AnimationOnScroll
    //   duration={duration}
    //   animateIn="animate__fadeInLeftBig"
    //   // animateOut="animate__fadeOutRightBig"
    // >
    <motion.div
      className={type === "vision" ? classes.visionCard : classes.valuesCard}
      initial={initial}
      animate={animate}
      variants={variants}
    >
      <div className={classes.header}>
        {/* <DashboardCustomizeOutlined /> */}
        <img src={icon} alt="icon" />
        <h3>{heading}</h3>
      </div>
      <p>{content}</p>
    </motion.div>
    // </AnimationOnScroll>
  );
}

export default Card;
