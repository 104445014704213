import React from "react";
import classes from "./ContactDetails.module.css";
import CallIcon from "@mui/icons-material/Call";
import EmailIcon from "@mui/icons-material/Email";

function ContactDetails() {
  return (
    <div className={classes.contactDetailsDiv}>
      <div className={classes.details}>
        <h5>RIYADH - KINGDOM OF SAUDI ARABIA</h5>
        <p>
          12445 Riyadh - KSA 4020 <br /> Alwaha Square, Othman Bin Affan Rd,
          Office #B207
        </p>
        <h6>
          <CallIcon />
          +966 23 456 6789
        </h6>
        <h6>
          <EmailIcon />
          info@xyz.com
        </h6>
      </div>
      {/* <div className={classes.map}> */}
      <iframe
        title="location"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d927756.5897719165!2d46.82252880000001!3d24.725191849999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e2f03890d489399%3A0xba974d1c98e79fd5!2sRiyadh%20Saudi%20Arabia!5e0!3m2!1sen!2sin!4v1698483035076!5m2!1sen!2sin"
        width="100%"
        height="380px"
        style={{ border: 0 }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
      {/* </div> */}
    </div>
  );
}

export default ContactDetails;
