import React from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import classes from "./Hero.module.css";
import ServiceCard from "./ServiceCard";
import { useTranslation } from "react-i18next";

const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 3,
    partialVisibilityGutter: 70,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 2, //changed from 3 to 2
    partialVisibilityGutter: 50,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
    partialVisibilityGutter: 40,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
    // partialVisibilityGutter: 40,
  },
};

function ServicesSlider({ services }) {
  return (
    // <div className={classes.wrapper}>
    <Carousel
      autoPlay
      autoPlaySpeed={3500}
      responsive={responsive}
      swipeable={true}
      draggable={true}
      arrows={false}
      //   showDots={true}
      infinite={true}
      partialVisible={true}
      // dotListClass="customDotListStyle"
    >
      {services.map((service) => (
        <ServiceCard icon={service.icon} name={service.title} />
      ))}
    </Carousel>
    // </div>
  );
}

export default ServicesSlider;
