import React, { useState } from "react";
import classes from "./ContactForm.module.css";

function ContactForm() {
  const initialFormData = {
    name: "",
    brandName: "",
    brandWebsite: "",
    email: "",
    contactNumber: "",
    message: "",
  };

  const [formData, setFormData] = useState(initialFormData);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  async function handleSubmit(e) {
    e.preventDefault();
    console.log(formData);

    // try {
    //   const response = await axios.post(
    //     `${BASE_URL}/send`,
    //     { inputData: formData },
    //     { withCredentials: true }
    //   );
    //   // setData(response.data.message);
    //   if (response.status === 200) {
    //     setFormData(initialFormData);
    //     return toast.success("Message sent successfully");
    //   } else {
    //     return toast.error("Something went wrong!");
    //   }
    // } catch (error) {
    //   console.error(error);
    //   toast.error("Something went wrong! Please try again later.");
    // }
  }

  return (
    <div className={classes.supportFormDiv}>
      <h4>DROP US A LINE</h4>
      <form onSubmit={handleSubmit}>
        <div className={classes.supportForm}>
          {/* <div className={classes.inputGroup}> */}
          <div className={classes.formInput}>
            <label>Name*</label>
            <input
              required
              value={formData.name}
              type="text"
              name="name"
              // placeholder="Name"
              onChange={handleInputChange}
            />
          </div>
          <div className={classes.formInput}>
            <label>Email*</label>

            <input
              required
              type="email"
              name="email"
              // placeholder="Email"
              value={formData.email}
              onChange={handleInputChange}
            />
          </div>
          {/* </div> */}

          {/* <div className={classes.inputGroup}> */}
          <div className={classes.formInput}>
            <label>Company*</label>

            <input
              required
              type="text"
              name="comppany"
              value={formData.company}
              onChange={handleInputChange}
            />
          </div>

          <div className={classes.formInput}>
            <label>Subject*</label>
            <input
              required
              type="text"
              name="subject"
              value={formData.subject}
              onChange={handleInputChange}
            />
          </div>
          {/* </div> */}
          {/* <div className={classes.formInput}>
            <label>Contact number*</label>

            <input
              required
              type="tel"
              name="contactNumber"
              // placeholder="Contact Number"
              value={formData.contactNumber}
              onChange={handleInputChange}
            />
          </div> */}
          {/* <div className={classes.formInput}> </div> */}
          {/* <div className={classes.formInput}> */}
          <div style={{ width: "100%" }}>
            <label>Your message*</label>
            <textarea
              required
              rows={10}
              name="message"
              // placeholder="Message*"
              value={formData.message}
              onChange={handleInputChange}
            />
          </div>
          <div className={classes.actions}>
            <button type="submit">Send message</button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ContactForm;
