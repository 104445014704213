import React, { useEffect, useRef, useState } from "react";
import Card from "./Card";
import classes from "./Values.module.css";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import trust from "../../../assets/icons/trust.svg";
import excellence from "../../../assets/icons/excellence.svg";
import people from "../../../assets/icons/value.svg";
import quality from "../../../assets/icons/quality.svg";
import AnimatedTextCharacter from "../../AnimatedTextCharacter";
import { motion, useAnimation, useInView } from "framer-motion";
import { useTranslation } from "react-i18next";

const ValuesIcons = [trust, excellence, people, quality];

function Values() {
  const { t } = useTranslation();
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true, amount: 0.3 });

  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      //fire the animation
      mainControls.start("visible");
    }
  }, [isInView]);

  const values = t("values.data", { returnObjects: true });


  return (
    <div className={classes.valuesDiv} ref={ref}>
      <h3>
        <AnimatedTextCharacter
          text={`${t("values.title")} -`}
          isVisible={isInView}
        />
        {/* <ArrowForwardIcon fontSize="large" /> */}
        {/* Our Values <ArrowForwardIcon fontSize="large" /> */}
      </h3>
      <div className={classes.cardsWrapper}>
        {values.map((value, i) => (
          <Card
            // ref={ref}
            key={i}
            type="values"
            heading={value.title}
            content={value.text}
            icon={ValuesIcons[i]}
            initial="hidden"
            animate={mainControls}
            variants={{
              hidden: {
                opacity: 1,
                x: -900,
              },
              visible: {
                opacity: 1,
                x: 0,
                transition: {
                  duration: (i + 1) * 0.4,
                  // delay: (i + 1) * 0.3,
                },
              },
            }}
          />
        ))}
      </div>
    </div>
  );
}

export default Values;
