import React, { useState } from "react";
import classes from "./ServiceDetailPage.module.css";
import { styled } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import image1 from "../../assets/tm1.webp";
import image2 from "../../assets/tm2.webp";
import image3 from "../../assets/tm3.webp";
import { useTranslation } from "react-i18next";

function TalentManagementContent() {
  const [expanded, setExpanded] = useState("");
  const { t } = useTranslation();
  const Tm = t("Our Services", {
    returnObjects: true
  })
  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <div className="container py-5">
        <div className={classes.intro}>
          <p>
            {Tm.TM.text}
          </p>
        </div>
      </div>
      <div className="container py-3">
        <div className={classes.detailCardsWrapper}>
          <div className={classes.detailCard}>
            <div className={classes.imgDiv}>
              <img src={image1} alt="" />
            </div>
            <div className={classes.contentDiv}>
              <h3>{Tm.TM.data[0].title}</h3>
              <h4>
                {Tm.TM.data[0].text}
              </h4>
              <h5>{Tm.TM.data[0].WhatWeDo.title}</h5>
              <p>
                {Tm.TM.data[0].WhatWeDo.txt}
              </p>
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <h6>{Tm.TM.data[0].WhatWeDo.data[0].title}</h6>
                </AccordionSummary>
                <AccordionDetails>
                  <p>
                  {Tm.TM.data[0].WhatWeDo.data[0].text}
                  </p>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <h6>{Tm.TM.data[0].WhatWeDo.data[1].title}</h6>
                </AccordionSummary>
                <AccordionDetails>
                  <p>
                  {Tm.TM.data[0].WhatWeDo.data[1].text}
                  </p>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  aria-controls="panel3-content"
                  id="panel3-header"
                >
                  <h6>{Tm.TM.data[0].WhatWeDo.data[2].title}</h6>
                </AccordionSummary>
                <AccordionDetails>
                  <p>
                  {Tm.TM.data[0].WhatWeDo.data[2].text}
                  </p>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <AccordionSummary
                  aria-controls="panel4-content"
                  id="panel4-header"
                >
                  <h6>{Tm.TM.data[0].WhatWeDo.data[3].title}</h6>
                </AccordionSummary>
                <AccordionDetails>
                  <p>
                  {Tm.TM.data[0].WhatWeDo.data[3].text}
                  </p>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          <div className={classes.detailCard}>
            <div className={classes.imgDiv}>
              <img src={image2} alt="" />
            </div>
            <div className={classes.contentDiv}>
              <h3>{Tm.TM.data[1].title}</h3>
              <h4>
               {Tm.TM.data[1].text}
              </h4>
              <p>
              {Tm.TM.data[1].data[0]} <br />
              {Tm.TM.data[1].data[1]} <br />

              </p>
              <p>
                <h5>We aligning the candidate to be:</h5>
                <ul>
                {Tm?.TM?.data[1]?.Benefits?.map((elem,index)=>{
                  return <li>{elem}</li>
                })}
                </ul>
              </p>
            </div>
          </div>
          <div className={classes.detailCard}>
            <div className={classes.imgDiv}>
              <img src={image3} alt="" />
            </div>
            <div className={classes.contentDiv}>
              <h3> {Tm.TM.data[2].title}</h3>
              <h4>
             {Tm.TM.data[2].text}
              </h4>
              <p>
              {Tm.TM.data[2].text2}
              </p>
              <p>
                <strong>{Tm.TM.data[2].TxtBold}</strong>
              {Tm.TM.data[2].text3}
              </p>
              <h5> {Tm.TM.data[2].Birkman[0].title}</h5>
              <p>
              {Tm.TM.data[2].Birkman[0].text}
                <ul>
                {Tm.TM.data[2].Birkman[0].data.map((elem,index)=>{
                  return <li key={index}>{elem}</li>
                })}

                </ul>
              </p>
              <h5>{Tm.TM.data[2].Birkman[1].text}</h5>
              <ul>
               {Tm.TM.data[2].Birkman[1].data.map(elem=><li>{elem}</li>)}
              </ul>
            </div>
          </div>
          <div className={classes.detailCard}>
            <div className={classes.imgDiv}>
              <img src={image3} alt="" />
            </div>
            <div className={classes.contentDiv}>
              <h3>{Tm.TM.data[3].title}</h3>
              <h4> {Tm.TM.data[3].text.substring(0,59)}</h4>
              <p>
              {Tm.TM.data[3].text.substring(59)}
              </p>
          
                <ul>
                {Tm.TM.data[3].data.map(elem=><li>{elem}</li>)}
                </ul>

            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TalentManagementContent;

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: "none",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<KeyboardArrowDownIcon sx={{ fontSize: "1.3rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "white" : "white",
  border: "none",
  borderBottom: "1px solid var(--color-primary)",
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  color: "#6b6b6b",
}));
