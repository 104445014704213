import React from "react";
import classes from "./ServiceDetailPage.module.css";
import image1 from "../../assets/otm2.webp";
import image2 from "../../assets/otm1.webp";
import image3 from "../../assets/otm3.webp";
import { useTranslation } from "react-i18next";

function OTMContent() {
  const { t } = useTranslation()
  const Os = t("Our Services", { returnObjects: true })
  return (
    <>
      <div className="container py-5">
        <div className={classes.intro}>
          <p>
            {Os.OS.title}, {Os.OS.text}
          </p>
          <p>
            {Os.OS.data.text2}
            <ul>
              {Os.OS.data.List.map(elem => <li>{elem}</li>)}
            </ul>
          </p>
        </div>
      </div>
      <div className="container py-3">
        <div className={classes.detailCardsWrapper}>
          <div className={classes.detailCard}>
            <div className={classes.imgDiv}>
              <img src={image2} alt="" />
            </div>
            <div className={classes.contentDiv}>
              <h3>{Os.OS.data.Content.BPM.title}</h3>
              <p>
                {Os.OS.data.Content.BPM.text1}
              </p>
              <p>
                {Os.OS.data.Content.BPM.text2.substring(0, 102)}
              </p>
              <p>
                {Os.OS.data.Content.BPM.text2.substring(102)}

              </p>
              <p>
                {Os.OS.data.Content.BPM.text3}
              </p>
            </div>
          </div>
          <div className={classes.detailCard} style={{ alignItems: "center" }}>
            <div className={classes.imgDiv} style={{ flex: "0.8" }}>
              <img
                src={image1}
                alt=""
              // style={{ width: "70%", margin: "auto" }}
              />
            </div>
            <div className={classes.contentDiv} style={{ flex: "1.2" }}>
              <h3>
                {Os.OS.data.Content.CM.title}
              </h3>
              <p>
                {Os.OS.data.Content.CM.text}
              </p>
            </div>
          </div>
          <div className={classes.detailCard}>
            <div className={classes.imgDiv}>
              <img src={image3} alt="" />
            </div>
            <div className={classes.contentDiv}>
              <h3> {Os.OS.data.Content.Governance.title}</h3>
              <p>
                {Os.OS.data.Content.Governance.text.substring(0,246)}
              </p>
              <p>
              {Os.OS.data.Content.Governance.text.substring(246)}
              </p>
              <p>
              {Os.OS.data.Content.Governance.text2.substring(0,67)}
              </p>
              <p>
              {Os.OS.data.Content.Governance.text2.substring(67)}
              </p>
              <p>
              <p>
              {Os.OS.data.Content.Governance.Content.text}
              </p>
                <ul>
                {Os.OS.data.Content.Governance.Content.functions.map(elem=><li>{elem}</li>)}
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OTMContent;
