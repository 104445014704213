import React from "react";
import classes from "./Navbar.module.css";
import { motion } from "framer-motion";

const variants = {
  open: {
    transition: {
      staggerChildren: 0.1, //top to bottom direction by default
    },
  },
  closed: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1, //to make the ditrection opposite, i.e bottom to top
    },
  },
};

const linkVariants = {
  open: {
    y: 0,
    opacity: 1,
  },
  closed: {
    y: 50,
    opacity: 0,
  },
};

function DropdownContent({ links }) {
  return (
    <motion.div className={classes.dropdownContent} variants={variants}>
      {links.map((link) => (
        <h6 key={link} variants={variants}>
          {link}
        </h6>
      ))}
    </motion.div>
  );
}

export default DropdownContent;
