import React, { useRef } from "react";
import { motion, useScroll, useTransform } from "framer-motion";
import classes from "./OurMethodology.module.css";
import {
  VerticalTimeline,
  VerticalTimelineElement,
} from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import CheckOutlinedIcon from "@mui/icons-material/CheckOutlined";
import examine from "../../../assets/icons/examine.svg";
import engage from "../../../assets/icons/engage.svg";
import execute from "../../../assets/icons/execute.svg";
import evaluate from "../../../assets/icons/evaluate.svg";
import { useTranslation, } from "react-i18next";
import Cookies from "js-cookie";

function OurMethodology() {
  const lang = Cookies.get("i18next")
  const ref = useRef();

  const { t } = useTranslation();
  const Methodology = t("Methodology", { returnObjects: true })
  const { scrollYProgress } = useScroll({
    target: ref,
    offset: ["start start", "end start"],
  });

  const yBg = useTransform(scrollYProgress, [0, 1], ["0%", "100%"]);

  return (
    <div className={classes.methodologyDiv} ref={ref}>
      <div className={classes.data}>
        <h3 className={classes.title}>{Methodology.title}</h3>
        <p>
          {Methodology.text}
        </p>
        <div className={classes.timelineDiv} style={{ direction: "ltr" }}>
          {/* <VerticalTimeline>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{
                background: "var(--color-primary)",
                color: "#fff",
              }}
              contentArrowStyle={{
                borderRight: "7px solid  var(--color-primary)",
              }}
              iconStyle={{
                background: "var(--color-primary)",
                color: "#fff",
              }}
              icon={<CheckOutlinedIcon />}
            >
              <h3 className="vertical-timeline-element-title">DIAGNOSE</h3>
              <p>
                Involves a meticulous analysis of the current situation,
                gathering relevant data, and identifying specific challenges or
                opportunities. It’s a foundation for understanding the
                intricacies of the issue at hand and assessing its impact on
                overarching goals or objectives.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{
                background: "rgb(247, 245, 245)",
                color: "#303133",
              }}
              contentArrowStyle={{
                borderRight: "7px solid  rgb(247, 245, 245)",
              }}
              iconStyle={{ background: "var(--color-primary)", color: "#fff" }}
              icon={<CheckOutlinedIcon />}
            >
              <h3 className="vertical-timeline-element-title">DIALOGUE</h3>

              <p>
                Establishes open and transparent communication channels among
                stakeholders, engaging key individuals or groups, encouraging
                feedback, and fostering a shared understanding through active
                conversation. Effective dialogue ensures that all perspectives
                are considered before moving forward with solutions.
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{
                background: "var(--color-primary)",
                color: "#fff",
              }}
              contentArrowStyle={{
                borderRight: "7px solid  var(--color-primary)",
              }}
              iconStyle={{ background: "var(--color-primary)", color: "#fff" }}
              icon={<CheckOutlinedIcon />}
            >
              <h3 className="vertical-timeline-element-title">DEPLOY</h3>

              <p>
                Deploying strategies involves the meticulous execution of
                planned solutions, including efficient resource allocation,
                progress monitoring, and providing necessary training and
                support, bringing envisioned plans to life in action
              </p>
            </VerticalTimelineElement>
            <VerticalTimelineElement
              className="vertical-timeline-element--work"
              contentStyle={{
                background: "rgb(247, 245, 245)",
                color: "#303133",
              }}
              contentArrowStyle={{
                borderRight: "7px solid  rgb(247, 245, 245)",
              }}
              iconStyle={{ background: "var(--color-primary)", color: "#fff" }}
              icon={<CheckOutlinedIcon />}
            >
              <h3 className="vertical-timeline-element-title">DETERMINE</h3>

              <p>
                Evaluating outcomes involves assessing the effectiveness of
                deployed solutions through performance analysis, feedback
                evaluation, and identifying lessons for improvement. This step
                not only gauges success but also informs adjustments for
                continuous enhancement in future consultations.
              </p>
            </VerticalTimelineElement>
          </VerticalTimeline> */}
          <VerticalTimeline>
            {
              Methodology.data.map((elem, i) => {
                if (lang === "ar") {
                  if (i % 2 !== 0) {
                    return <VerticalTimelineElement
                      className="vertical-timeline-element--work"
                      contentStyle={{
                        background: "rgb(247, 245, 245)",
                        color: "#303133",
                        textAlign: "end"
                      }}
                      contentArrowStyle={{
                        borderRight: "7px solid  rgb(247, 245, 245)",
                      }}
                      iconStyle={{ background: "var(--color-primary)", color: "#fff" }}
                      icon={<CheckOutlinedIcon />}
                    >
                      <h3 className="vertical-timeline-element-title">{elem.title}</h3>

                      <p>
                        {elem.text}
                      </p>
                    </VerticalTimelineElement>
                  }
                  else {
                    return <VerticalTimelineElement
                      className="vertical-timeline-element--work"
                      contentStyle={{
                        background: "var(--color-primary)",
                        color: "#fff",
                        textAlign: "end"
                      }}
                      contentArrowStyle={{
                        borderRight: "7px solid  var(--color-primary)",
                      }}
                      iconStyle={{
                        background: "var(--color-primary)",
                        color: "#fff",
                      }}
                      icon={<CheckOutlinedIcon />}
                    >
                      <h3 className="vertical-timeline-element-title">{elem?.title}</h3>
                      <p>
                        {elem?.text}
                      </p>
                    </VerticalTimelineElement>
                  }
                }else{
                  if (i % 2 !== 0) {
                    return <VerticalTimelineElement
                      className="vertical-timeline-element--work"
                      contentStyle={{
                        background: "rgb(247, 245, 245)",
                        color: "#303133",
                      }}
                      contentArrowStyle={{
                        borderRight: "7px solid  rgb(247, 245, 245)",
                      }}
                      iconStyle={{ background: "var(--color-primary)", color: "#fff" }}
                      icon={<CheckOutlinedIcon />}
                    >
                      <h3 className="vertical-timeline-element-title">{elem.title}</h3>

                      <p>
                        {elem.text}
                      </p>
                    </VerticalTimelineElement>
                  }
                  else {
                    return <VerticalTimelineElement
                      className="vertical-timeline-element--work"
                      contentStyle={{
                        background: "var(--color-primary)",
                        color: "#fff",
                      }}
                      contentArrowStyle={{
                        borderRight: "7px solid  var(--color-primary)",
                      }}
                      iconStyle={{
                        background: "var(--color-primary)",
                        color: "#fff",
                      }}
                      icon={<CheckOutlinedIcon />}
                    >
                      <h3 className="vertical-timeline-element-title">{elem?.title}</h3>
                      <p>
                        {elem?.text}
                      </p>
                    </VerticalTimelineElement>
                  }
                }
              })
            }

          </VerticalTimeline>
        </div>
      </div>
    </div>
  );
}

export default OurMethodology;
