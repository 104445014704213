import React, { useEffect, useState } from "react";
import classes from "./Navbar.module.css";
import { NavLink, Link, useNavigate, useLocation } from "react-router-dom";
// import modistLogo from "../assets/logo.jpg";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
import ReponsiveNavbar from "./ReponsiveNavbar";
import DropdownContent from "./DropdownContent";
import logo from "../../assets/logo-light.png";

import { useTranslation } from "react-i18next";
import i18next from "i18next";
import cookies from "js-cookie";

const languages = [
  {
    code: "en",
    name: "English",
    country_code: "gb", //gb for Great Britain (UK)
  },
  {
    code: "ar",
    name: "العربية",
    country_code: "sa", //sa for Saudi Arabia
    dir: "rtl",
  },
];

function Navbar(props) {
  const { t } = useTranslation();
  const currentLanguageCode = cookies.get("i18next") || "en"; //"i18next" is the cookie name which has en/ar/fr value
  console.log(currentLanguageCode);

  const currentLanguage = languages.find(
    (lang) => lang.code === currentLanguageCode
  );

  const { name, country_code } = currentLanguage;

  const [openNavbar, setOpenNavbar] = useState(false);
  const [showTechMenu, setTechMenu] = useState(false);
  const [showAboutMenu, setAboutMenu] = useState(false);
  const [showLogisticsMenu, setLogisticsMenu] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  function responsiveNavbarHandler() {
    setOpenNavbar((prev) => !prev);
  }

  function sendToSection(sectionId) {
    const element = document.getElementById(sectionId);
    if (element) {
      // 👇 Will scroll smoothly to the top of the next section
      element.scrollIntoView({ behavior: "smooth" });
    }
    if (openNavbar) setOpenNavbar(!openNavbar);
  }

  const hamburgerMenuBtn = (
    <span className={classes.menuBtn} onClick={responsiveNavbarHandler}>
      &#9776;
    </span>
  );

  const langDropdown = (
    <div className="dropdown">
      <button
        className="btn btn-link dropdown-toggle text-white text-decoration-none"
        type="button"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        <span
          className={`fi fi-${country_code} mx-2`}
          style={{ opacity: 1 }}
        ></span>
        {/* {name} */}
      </button>
      <ul className="dropdown-menu">
        <li>
          <span className="dropdown-item-text">{t("language")}</span>
        </li>
        {languages.map(({ code, name, country_code }) => (
          <li key={country_code}>
            <button
              className="dropdown-item"
              onClick={() => i18next.changeLanguage(code)}
              disabled={code === currentLanguageCode}
            >
              <span
                className={`fi fi-${country_code} mx-2`}
                style={{
                  opacity: code === currentLanguageCode ? 0.5 : 1,
                }}
              ></span>
              {name}
            </button>
          </li>
        ))}
      </ul>
    </div>
  );

  // const responsiveSideNavbar = (

  // );

  return (
    <div className={classes.navbarWrapper}>
      <div className={classes.navbar}>
        <div className={classes.navbarStart}>
          {langDropdown}
          <Link to="/" className={classes.logo}>
            {/* <h1>
              <span style={{ color: "white" }}>HR</span>-
              <span style={{ color: "white" }}>SaaS</span>
            </h1> */}
            <img alt="brand-logo" src={logo} />
          </Link>
        </div>

        {!openNavbar ? (
          hamburgerMenuBtn
        ) : (
          <ReponsiveNavbar
            sendToSection={sendToSection}
            responsiveNavbarHandler={responsiveNavbarHandler}
          />
        )}

        <nav className={classes.navMenu}>
          <ul>
            <li className={`${classes.navListItem}`}>
              <p onClick={() => sendToSection("homeSection")}>Home</p>
            </li>
            <li className={`${classes.navListItem} ${classes.dropdownMenu}`}>
              <p onClick={() => sendToSection("aboutSection")}>About Us</p>
            </li>
            <li className={`${classes.navListItem} ${classes.dropdownMenu}`}>
              <p onClick={() => sendToSection("servicesSection")}>Services </p>
            </li>

            <li className={`${classes.navListItem}`}>
              <p onClick={() => sendToSection("teamSection")}>Our Team</p>
            </li>

            <li className={`${classes.navListItem}`}>
              <p onClick={() => sendToSection("contactSection")}>Contact Us</p>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default Navbar;

// import React, { useEffect, useState } from "react";
// import classes from "./Navbar.module.css";
// import { NavLink, Link, useNavigate, useLocation } from "react-router-dom";
// // import modistLogo from "../assets/logo.jpg";
// import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";
// import ReponsiveNavbar from "./ReponsiveNavbar";
// import DropdownContent from "./DropdownContent";

// function Navbar(props) {
//   const [openNavbar, setOpenNavbar] = useState(false);
//   const [showTechMenu, setTechMenu] = useState(false);
//   const [showAboutMenu, setAboutMenu] = useState(false);
//   const [showLogisticsMenu, setLogisticsMenu] = useState(false);
//   const navigate = useNavigate();
//   const location = useLocation();

//   function responsiveNavbarHandler() {
//     setOpenNavbar((prev) => !prev);
//   }

//   // function sendToContactForm() {
//   //   navigate("/contact-us");
//   // }

//   async function sendToSection(url, sectionId) {
//     // props.onSectionSelect(url, sectionId);
//   }

//   // async function sendToSection(url, sectionId) {
//   //   if (location.pathname !== url) {
//   //     await navigate(url);
//   //   }

//   //   const element = document.getElementById(sectionId);
//   //   if (element) {
//   //     // 👇 Will scroll smoothly to the top of the next section
//   //     element.scrollIntoView({ behavior: "smooth" });
//   //   }
//   // }

//   const hamburgerMenuBtn = (
//     <span className={classes.menuBtn} onClick={responsiveNavbarHandler}>
//       &#9776;
//     </span>
//   );

//   // const responsiveSideNavbar = (

//   // );

//   return (
//     <div className={classes.navbarWrapper}>
//       <div className={classes.navbar}>
//         <Link to="/" className={classes.logo}>
//           <h1>
//             <span style={{ color: "white" }}>HR</span>-
//             <span style={{ color: "white" }}>SaaS</span>
//           </h1>
//           {/* <img alt="brand-logo" src="" />
//           <p>modern distributor</p> */}
//         </Link>
//         {!openNavbar ? (
//           hamburgerMenuBtn
//         ) : (
//           <ReponsiveNavbar
//             sendToSection={sendToSection}
//             responsiveNavbarHandler={responsiveNavbarHandler}
//           />
//         )}

//         <nav className={classes.navMenu}>
//           <ul>
//             <li className={`${classes.navListItem}`}>
//               <NavLink
//                 to="/"
//                 className={({ isActive }) => (isActive ? classes.active : "")}
//               >
//                 Home
//               </NavLink>
//             </li>
//             <li className={`${classes.navListItem} ${classes.dropdownMenu}`}>
//               <NavLink
//                 to="/about-us"
//                 className={({ isActive }) => (isActive ? classes.active : "")}
//               >
//                 About Us
//                 {/* <KeyboardArrowDownOutlinedIcon
//                   fontSize="medium"
//                   onClick={() => setAboutMenu((prev) => !prev)}
//                 /> */}
//               </NavLink>
//               {/* <DropdownContent
//                 links={[
//                   "Company Overview",
//                   "History",
//                   "Affiliate Program",
//                   "Careers",
//                   "HR Portal",
//                   "Social Media",
//                   "Our Team",
//                 ]}
//               /> */}
//             </li>
//             <li className={`${classes.navListItem} ${classes.dropdownMenu}`}>
//               <NavLink
//                 to="/services"
//                 className={({ isActive }) => (isActive ? classes.active : "")}
//               >
//                 Services{" "}
//                 <KeyboardArrowDownOutlinedIcon
//                   fontSize="medium"
//                   onClick={() => setLogisticsMenu((prev) => !prev)}
//                 />
//               </NavLink>
//               <DropdownContent
//                 links={[
//                   "Overview",
//                   "HR Management",
//                   "Risk Management",
//                   "Employee Benefits",
//                 ]}
//               />
//             </li>
//             {/* <li className={`${classes.navListItem} ${classes.dropdownMenu}`}>
//               <NavLink
//                 to="/industries"
//                 className={({ isActive }) => (isActive ? classes.active : "")}
//               >
//                 Industries
//                 <KeyboardArrowDownOutlinedIcon
//                   fontSize="medium"
//                   onClick={() => setTechMenu((prev) => !prev)}
//                 />
//               </NavLink>
//               <DropdownContent
//                 links={[
//                   "Industries Overview",
//                   "Franchise Owners",
//                   "Health & Medicine",
//                   "Skilled Trades",
//                 ]}
//               />
//             </li> */}

//             <li className={`${classes.navListItem}`}>
//               <NavLink
//                 to="/our-team"
//                 className={({ isActive }) => (isActive ? classes.active : "")}
//               >
//                 Our Team
//               </NavLink>
//             </li>

//             <li className={`${classes.navListItem}`}>
//               <NavLink
//                 to="/contact-us"
//                 className={({ isActive }) => (isActive ? classes.active : "")}
//               >
//                 Contact Us
//               </NavLink>
//             </li>
//           </ul>
//         </nav>
//       </div>
//     </div>
//   );
// }

// export default Navbar;
