import React from "react";
import classes from "./ContactUs.module.css";
import ContactForm from "./ContactForm";
import ContactDetails from "./ContactDetails";

function ContactUs() {
  return (
    <div className={classes.contactDiv} id="contactSection">
      <h3>Get In Touch</h3>
      <div className={classes.wrapper}>
        <div className={classes.gridItem1}>
          <ContactDetails />
        </div>
        <div className={classes.gridItem2}>
          <ContactForm />
        </div>
      </div>
    </div>
  );
}

export default ContactUs;
