import React, { useState } from "react";
import classes from "./Navbar.module.css";
import { NavLink, Link } from "react-router-dom";
import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

function ReponsiveNavbar({ responsiveNavbarHandler, sendToSection }) {
  const [dropdown, setDropdown] = useState(false);

  return (
    <div className={classes.dropdown}>
      <Link
        to="#"
        className={classes.closeBtn}
        onClick={responsiveNavbarHandler}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="25"
          height="25"
          fill="currentColor"
          className="bi bi-x-lg"
          viewBox="0 0 16 16"
        >
          <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
        </svg>
      </Link>
      <div className={`${classes.dropdownLink} ${classes.sellBtn}`}>
        <p onClick={() => sendToSection("homeSection")}>Home</p>
      </div>
      <div className={`${classes.dropdownLink} ${classes.sellBtn}`}>
        <p onClick={() => sendToSection("aboutSection")}>About Us</p>
      </div>
      {/* <div className={`${classes.dropdownLink} ${classes.dropdownMenu}`}> */}
      <div className={`${classes.dropdownLink} ${classes.sellBtn}`}>
        <p
          onClick={() => sendToSection("servicesSection")}
          // onClick={() => setDropdown((prev) => !prev)}
        >
          Services
        </p>
      </div>
      <div className={`${classes.dropdownLink} ${classes.sellBtn}`}>
        <p onClick={() => sendToSection("teamSection")}>Our Team</p>
      </div>

      <div className={`${classes.dropdownLink} ${classes.sellBtn}`}>
        <p onClick={() => sendToSection("contactSection")}>Contact Us</p>
      </div>
    </div>
  );
}

export default ReponsiveNavbar;

// import React, { useState } from "react";
// import classes from "./Navbar.module.css";
// import { NavLink, Link } from "react-router-dom";
// import KeyboardArrowDownOutlinedIcon from "@mui/icons-material/KeyboardArrowDownOutlined";

// function ReponsiveNavbar({ responsiveNavbarHandler }) {
//   const [dropdown, setDropdown] = useState(false);

//   return (
//     <div className={classes.dropdown}>
//       <Link
//         to="#"
//         className={classes.closeBtn}
//         onClick={responsiveNavbarHandler}
//       >
//         <svg
//           xmlns="http://www.w3.org/2000/svg"
//           width="25"
//           height="25"
//           fill="currentColor"
//           className="bi bi-x-lg"
//           viewBox="0 0 16 16"
//         >
//           <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
//         </svg>
//       </Link>
//       <div className={`${classes.dropdownLink} ${classes.sellBtn}`}>
//         <Link to="/">Home</Link>
//       </div>
//       <div className={`${classes.dropdownLink} ${classes.sellBtn}`}>
//         <Link to="/">About Us</Link>
//       </div>
//       <div className={`${classes.dropdownLink} ${classes.dropdownMenu}`}>
//         <NavLink
//           to="/"
//           // className={({ isActive }) => (isActive ? classes.active : "")}
//           style={{ display: "flex", alignItems: "center" }}
//           onClick={() => setDropdown((prev) => !prev)}
//         >
//           Services <KeyboardArrowDownOutlinedIcon fontSize="medium" />
//         </NavLink>
//         {dropdown && (
//           <div className={classes.dropdownContent}>
//             <h6>Overview</h6>
//             <h6>HR Transformation</h6>
//             <h6>Learning & Improvement</h6>
//             <h6>Talent Attraction</h6>
//             <h6>Engagement</h6>
//           </div>
//         )}
//       </div>
//       <div className={`${classes.dropdownLink} ${classes.sellBtn}`}>
//         <Link to="/contact-us">Our Team</Link>
//       </div>

//       <div className={`${classes.dropdownLink} ${classes.sellBtn}`}>
//         <Link to="/contact-us">Contact Us</Link>
//       </div>
//     </div>
//   );
// }

// export default ReponsiveNavbar;
