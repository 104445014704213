import React from "react";
import Banner from "../components/ServiceDetailPage/Banner";
import { useParams } from "react-router-dom";
import hctm from "../assets/hctmbanner.webp";
import otm from "../assets/organisationalTranformBanner.webp";
import talent from "../assets/talenManagementbanner.webp";
import engagement from "../assets/Engagementbanner.webp";
import OTMContent from "../components/ServiceDetailPage/OTMContent";
import HCTMContent from "../components/ServiceDetailPage/HCTMContent";
import TalentManagementContent from "../components/ServiceDetailPage/TalentManagementContent";
import EngagementContent from "../components/ServiceDetailPage/EngagementContent";

function ServiceDetailPage() {
  const { name } = useParams();
  console.log(name);

  let title = "";
  let banner = "";

  if (name === "hc-transformation-management") {
    title = "Human Capital Transformation Management";
    banner = hctm;
  } else if (name === "organisational-transformation") {
    title = "Organisational Transformation Management";
    banner = otm;
  } else if (name === "talent-management") {
    title = "Talent Management";
    banner = talent;
  } else if (name === "employees-engagement") {
    title = "Employees Engagement";
    banner = engagement;
  }

  return (
    <div>
      <Banner title={title} banner={banner} />
      {name === "hc-transformation-management" && <HCTMContent />}
      {name === "organisational-transformation" && <OTMContent />}
      {name === "talent-management" && <TalentManagementContent />}
      {name === "employees-engagement" && <EngagementContent />}
    </div>
  );
}

export default ServiceDetailPage;
