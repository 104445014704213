import React, { useEffect, useRef, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import classes from "./Testimonials.module.css";
import Review from "./Review";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import clients from "../../../assets/clients.jpg";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";
import AnimatedTextCharacter from "../../AnimatedTextCharacter";
import { useAnimation, useInView } from "framer-motion";
import { motion } from "framer-motion";

const REVIEWS = [
  {
    name: "John Doe",
    review:
      "The HR consulting services provided were truly exceptional. They not only helped streamline our hiring process but also significantly improved employee satisfaction levels. The consultants displayed deep expertise and a keen understanding of our industry's unique challenges. I highly recommend their services.",
  },
  {
    name: "Jane Smith",
    review:
      "I was thoroughly impressed with the expertise and professionalism exhibited by the consultants. They provided invaluable insights on talent management strategies that have already begun to yield positive results. Their ability to tailor solutions to our specific needs sets them apart in the industry.",
  },
  {
    name: "Michael Johnson",
    review:
      "The HR team demonstrated remarkable responsiveness and efficiency throughout our engagement. They expertly guided us through complex HR compliance issues, making the process feel seamless and stress-free. Their attention to detail and comprehensive approach were instrumental in ensuring our compliance and mitigating risks.",
  },
  {
    name: "Sarah Davis",
    review:
      "The consulting firm completely transformed our HR department. Their innovative strategies and forward-thinking initiatives had an immediate impact on employee morale and productivity levels. The dedication and passion exhibited by the consultants were palpable and greatly contributed to our organization's success.",
  },
  {
    name: "David Brown",
    review:
      "Working with the consultants was a true pleasure. Their in-depth knowledge and ability to communicate complex concepts in an understandable manner were invaluable. They provided tailored solutions that precisely addressed our organization's specific needs. I have no hesitation in recommending their services to others in the industry.",
  },
];

function Testimonials() {
  const [index, setIndex] = useState(0);

  const ref = useRef(null);

  const isInView = useInView(ref, { once: true, amount: 0.4 });

  const mainControls = useAnimation();

  useEffect(() => {
    if (isInView) {
      //fire the animation
      mainControls.start("visible");
    }
  }, [isInView]);

  const handleSelect = (selectedIndex) => {
    setIndex(selectedIndex);
  };

  return (
    <div className={classes.testimonialsDiv} ref={ref}>
      <div className={classes.icon}>
        <FavoriteBorderIcon fontSize="large" />
      </div>
      <h3>
        <AnimatedTextCharacter
          text="What Our Clients Say About Us"
          isVisible={isInView}
        />
      </h3>

      <div className={classes.wrapperDiv}>
        <motion.div
          className={classes.cientsImgDiv}
          initial="hidden"
          animate={mainControls}
          variants={{
            hidden: {
              opacity: 0,
              x: -200,
            },
            visible: {
              opacity: 1,
              x: 0,
              transition: {
                duration: 0.6,
                delay: 0.3,
              },
            },
          }}
        >
          <img src={clients} alt="clients" />
        </motion.div>

        <motion.div
          className={classes.carouselDiv}
          initial="hidden"
          animate={mainControls}
          variants={{
            hidden: {
              opacity: 0,
              x: 200,
            },
            visible: {
              opacity: 1,
              x: 0,
              transition: {
                duration: 0.6,
                delay: 0.3,
              },
            },
          }}
        >
          <Carousel
            // fade
            activeIndex={index}
            onSelect={handleSelect}
            //data-bs-pause="false"
            pause={false}
            controls={false}
            // indicators={false}
          >
            {REVIEWS.map((data) => (
              <Carousel.Item>
                <Review data={data} />
              </Carousel.Item>
            ))}
          </Carousel>
        </motion.div>
      </div>
    </div>
  );
}

export default Testimonials;
