import React from "react";
import classes from "./Banner.module.css";

function Banner({ title, banner }) {
  console.log(banner);

  return (
    <div
      className={classes.banner}
      id="homeSection"
      style={{
        backgroundImage: `linear-gradient(#1b1d1fe0 1%, rgba(0, 17, 56, 0.400)),
    url(${banner})`,
      }}
    >
      <div className={classes.bannerContent}>
        <h2>{title}</h2>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Curabitur
          interdum, lacus a semper porta, tellus est lacinia augue, quis auctor
          turpis nisi eget erat. Donec eu est ac lorem blandit malesuada. Etiam
          vel elementum sapien. Phasellus molestie euismod nisl, eu ullamcorper
          mi finibus fringilla. Fusce feugiat dui vel nibh dignissim.
        </p>
      </div>
    </div>
  );
}

export default Banner;
