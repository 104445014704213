import React from "react";
import classes from "./Footer.module.css";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import CallIcon from "@mui/icons-material/Call";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import { Link } from "react-router-dom";
import logo from "../assets/logo-light.png";

function Footer() {
  return (
    <>
      <div className={classes.footer}>
        <div className={classes["footer-header"]}>
          <div className={classes["footer-row"]}>
            <Link to="/" className={classes.logo}>
              {/* <h1>
                <span style={{ color: "white" }}>HR</span>-
                <span style={{ color: "white" }}>SaaS</span>
              </h1> */}
              <img src={logo} alt="brand-logo" />
            </Link>
          </div>
          <div className={classes["footer-row"]}>
            <h4>INFORMATION</h4>
            <p>
              <Link to="/">Home</Link>
            </p>
            <p>
              <Link to="/">About us</Link>
            </p>
            {/* <p>Business</p> */}

            <p>
              <Link to="/">Services</Link>
            </p>
            <p>
              <Link to="/">Industries</Link>
            </p>
            <p>
              <Link to="/">Careers</Link>
            </p>
          </div>
          <div className={classes["footer-row"]}>
            <h4>RESOURCES</h4>
            <p>
              <Link to="/">Terms and conditions</Link>
            </p>
            <p>
              <Link to="/">Privacy policy</Link>
            </p>

            {/* <p>Copyright Information</p> */}
            <p>
              <Link to="/">Cookies policy</Link>
            </p>
          </div>
          <div className={classes["footer-row"]}>
            <h4>CONTACT</h4>

            <p className={classes.contactLink}>
              <Link to="tel:+918787787878">
                <CallIcon fontSize="small" />
                7878867678
              </Link>
            </p>
            <p className={classes.contactLink}>
              <Link to="mailto:xyz@gmail.com">
                <EmailOutlinedIcon fontSize="small" />
                xyz@gmail.com
              </Link>
            </p>
          </div>
          <div className={classes["footer-row"]}>
            <h4>SOCIAL MEDIA</h4>
            <div className={classes.socialMediaIcons}>
              <InstagramIcon />
              <FacebookIcon />
              <TwitterIcon />
            </div>
          </div>
        </div>
      </div>
      <div className={classes["footer-bottom"]}>
        <p> Trademark All rights reserved</p>
      </div>
    </>
  );
}

export default Footer;
