import React from "react";
import Navbar from "../components/Navbar/Navbar";
import Hero from "../components/LandingPage/Hero_First/Hero";
import AboutUs from "../components/LandingPage/Second/AboutUs";
import OurMethodology from "../components/LandingPage/Third/OurMethodology";
import ContactUs from "../components/LandingPage/Last/ContactUs";
import Counter from "../components/LandingPage/Fifth/Counter";
import Services from "../components/LandingPage/Fourth/Services";
import Testimonials from "../components/LandingPage/Testimonials/Testimonials";
import Footer from "../components/Footer";
import Brands from "../components/LandingPage/Brands/Brands";
import Partners from "../components/LandingPage/Partners/Partners";
import Team from "../components/LandingPage/Team/Team";

function LandingPage() {
  return (
    <>
      {/* <Navbar /> */}
      <Hero />
      <AboutUs />
      <OurMethodology />
      <Services />
      <Counter />
      <Partners />
      <Team />
      <Brands />
      {/* <Testimonials /> */}
      <hr />
      <ContactUs />
      {/* <Footer /> */}
      {/* <div style={{ height: "100vh" }}></div> */}
    </>
  );
}

export default LandingPage;
