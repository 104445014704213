import React, { useEffect, useRef, useState } from "react";
import classes from "./Why.module.css";
import AnimatedTextCharacter from "../../AnimatedTextCharacter";
import { AnimationOnScroll } from "react-animation-on-scroll";
import "animate.css/animate.min.css";
import { motion, useAnimation, useInView } from "framer-motion";
import { useTranslation } from "react-i18next";

function Why() {
  const elementRef = useRef(null);
  // const [isVisible, setIsVisible] = useState(false);
  const isInView = useInView(elementRef, { once: true });

  const { t } = useTranslation();
  const Choose = t("Choose_Us", { returnObjects: true })

  const headingVariant = {
    hidden: {
      opacity: 0,
      x: -100,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
        delay: 0.25,
      },
    },
  };

  const contentVariant = {
    hidden: {
      opacity: 0,
      x: 100,
    },
    visible: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 0.5,
        delay: 0.25,
      },
    },
  };

  const mainControls = useAnimation();

  // useEffect(() => {
  //   const observer = new IntersectionObserver(
  //     ([entry]) => {
  //       setIsVisible(entry.isIntersecting);
  //     },
  //     { threshold: 0.1 } // Adjust threshold as needed
  //   );

  //   if (elementRef.current) {
  //     observer.observe(elementRef.current);
  //   }

  //   return () => {
  //     if (elementRef.current) {
  //       observer.unobserve(elementRef.current);
  //     }
  //   };
  // }, []);

  useEffect(() => {
    if (isInView) {
      //fire the animation
      mainControls.start("visible");
    }
  }, [isInView]);

  return (
    <div className={classes.whyDiv}>
      <h3>{Choose.title}</h3>
      <div className={classes.contentDiv} ref={elementRef}>
        {/* <AnimationOnScroll duration={0.3} animateIn="animate__fadeInLeft"> */}
        <motion.h4
          // ref={elementRef}
          initial="hidden"
          animate={mainControls}
          variants={headingVariant}
        >
          <AnimatedTextCharacter
            text="We are a preferred & trusted partner of choice"
            isVisible={isInView}
          />
        </motion.h4>
        {/* </AnimationOnScroll> */}

        {/* <AnimatedTextCharacter
          text="We are a preferred &
          trusted partner of choice"
        /> */}
        {/* <AnimationOnScroll duration={0.3} animateIn="animate__fadeInRight"> */}
        <motion.p
          initial="hidden"
          animate={mainControls}
          variants={contentVariant}
        >
        {Choose.data.map((elem)=>{
          return <li>
            <h2>{elem.title}:</h2>
            <span>{elem.text}</span>
          </li>
        })}
        </motion.p>
        {/* </AnimationOnScroll> */}
      </div>
    </div>
  );
}

export default Why;
