import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "./pages/LandingPage";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";
import ServiceDetailPage from "./pages/ServiceDetailPage";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer";
import GoToTop from "./components/GoToTop";

const languages = [
  {
    code: "en",
    name: "English",
    country_code: "gb",
  },
  {
    code: "ar",
    name: "العربية",
    country_code: "sa",
    dir: "rtl",
  },
];

function App() {
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

  console.log(cookies.get("i18next"));

  const { t } = useTranslation();

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    document.title = t("app_title");
  }, [currentLanguage, t]);

  return (
    <>
      <Router>
        <GoToTop />
        <Navbar />
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/en/services/:name" element={<ServiceDetailPage />} />
          <Route path="/ar/services/:name" element={<ServiceDetailPage />} />

        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
