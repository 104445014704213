import React from "react";
import classes from "./Partners.module.css";

import Annie from "../../../assets/tools/App-Annie.jpg";
import Buffers from "../../../assets/tools/Buffer.png";
import FacebookAds from "../../../assets/tools/Facebook-Ads.jpg";
import GoogleAds from "../../../assets/tools/Google-Ads.jpg";
import GoogleAnalytics from "../../../assets/tools/Google-Analytics.jpg";
import GoogleConsole from "../../../assets/tools/Google-Search-Console.jpg";
import Grammarly from "../../../assets/tools/Grammarly.png";
import HootSuite from "../../../assets/tools/HootSuite.png";
import MailChimp from "../../../assets/tools/MailChimp.jpg";
import Shopify from "../../../assets/tools/Shopify.webp";
import Spyfu from "../../../assets/tools/Spyfu.jpg";
import TwitterAds from "../../../assets/tools/Twitter-Ads.jpg";
import PartnersSlider from "./PartnersSlider";

import partner from "../../../assets/partner.webp";

const logos = [Annie, Buffers, FacebookAds, GoogleAds, GoogleAnalytics, GoogleConsole, Grammarly, HootSuite, MailChimp, Shopify, Spyfu, TwitterAds];

function Partners() {
  return (
    <div className={classes.partnersDiv}>
      <h3>Our Partners</h3>
      <p>
        Together, we shape the future of work with unmatched HR expertise. Our strategic partnerships are the cornerstone of our commitment to delivering exceptional HR solutions that drive growth and
        success for your business.
      </p>
      {/* <hr width="10%" color="#012d36" size="8" align="left" /> */}

      <div className={classes.partnersSlider}>
        {/* 
       <PartnersSlider logos={logos} />
       */}
        <div className='row align-items-center'>
          <div className={`col-md-5 ${classes.imgDiv}`}>
            <img src={partner} alt='partner-logo' />
          </div>
          <div className='col-md-7'>
            <p>
              <strong>CustomInsight</strong> specializes in 360 degree feedback, employee surveys, and other tools that help companies of any size engage and develop their employees, managers, and
              leaders.
            </p>
          </div>
        </div>
      </div>

      {/* <hr /> */}
    </div>
  );
}

export default Partners;
