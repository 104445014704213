import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import classes from "./ServiceDetailPage.module.css";
import image1 from "../../assets/hctm1.webp";
import image2 from "../../assets/hctm2.webp";
import image3 from "../../assets/hctm3.webp";
import { useTranslation } from "react-i18next";
function HCTMContent() {
  const [expanded, setExpanded] = useState("");
  const { t } = useTranslation()
  const Hc = t("Our Services", { returnObjects: true })

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <>
      <div className="container py-5">
        <div className={classes.intro}>
          <p>
            {Hc.HC.text}
            <br />
            {Hc.HC.text2}
          </p>
        </div>
      </div>
      <div className="container py-3">
        <div className={classes.detailCardsWrapper}>
          <div className={classes.detailCard}>
            <div className={classes.imgDiv}>
              <img src={image1} alt="" />
            </div>
            <div className={classes.contentDiv}>
              <h3>{Hc.HC.data[0].title}</h3>
              <p>
               {Hc.HC.data[0].text}
              </p>
              <h5> {Hc.HC.data[0].Rewards.text}</h5>

              {/* can use accordions here */}
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
              >
                <AccordionSummary
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <h6>1.{Hc.HC.data[0].Rewards.Compensation.title}</h6>
                </AccordionSummary>
                <AccordionDetails>
                  <p>
                    {Hc.HC.data[0].Rewards.Compensation.text}
                    <ul>
                      <li>{Hc.HC.data[0].Rewards.Compensation.data.Fixed}</li>
                      <li>
                        {Hc.HC.data[0].Rewards.Compensation.data.Variable.title}
                        <ul>
                          <li>
                            {Hc.HC.data[0].Rewards.Compensation.data.Variable.List}
                          </li>
                          <li>
                            {Hc.HC.data[0].Rewards.Compensation.data.Variable.List1}

                          </li>
                          <li>
                            {Hc.HC.data[0].Rewards.Compensation.data.Variable.List2}

                          </li>
                          <li>                          {Hc.HC.data[0].Rewards.Compensation.data.Variable.List3}
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </p>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel2"}
                onChange={handleChange("panel2")}
              >
                <AccordionSummary
                  aria-controls="panel2-content"
                  id="panel2-header"
                >
                  <h6>2. {Hc.HC.data[0].Rewards.Benefits.title}</h6>
                </AccordionSummary>
                <AccordionDetails>
                  <p>
                    {Hc.HC.data[0].Rewards.Benefits.text}
                    <ul>
                      <li>{Hc.HC.data[0].Rewards.Benefits.data.List1}</li>
                      <li>{Hc.HC.data[0].Rewards.Benefits.data.List2}</li>
                      <li>{Hc.HC.data[0].Rewards.Benefits.data.List3}</li>
                      <li>{Hc.HC.data[0].Rewards.Benefits.data.List4}</li>
                      <li>{Hc.HC.data[0].Rewards.Benefits.data.List5}</li>
                    </ul>
                  </p>
                </AccordionDetails>
              </Accordion>

              <Accordion
                expanded={expanded === "panel3"}
                onChange={handleChange("panel3")}
              >
                <AccordionSummary
                  aria-controls="panel3-content"
                  id="panel3-header"
                >
                  <h6>3. {Hc.HC.data[0].Rewards.Philosophy.title}</h6>
                </AccordionSummary>
                <AccordionDetails>
                  <p>
                    <ul>
                      {Hc.HC.data[0].Rewards.Philosophy.data.map((elem) => {
                        return <li>{elem}</li>
                      })}
                    </ul>
                  </p>
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          <div className={classes.detailCard}>
            <div className={classes.imgDiv}>
              <img src={image2} alt="" />
            </div>
            <div className={classes.contentDiv}>
              <h3>{Hc.HC.data[1].Organizational_Architecture.title}</h3>
              <p>
                <strong>{Hc.HC.data[1].Organizational_Architecture.data.Orgainizational_Structure.title}</strong> {Hc.HC.data[1].Organizational_Architecture.data.Orgainizational_Structure.text}
                <ul>
                  <li>{Hc.HC.data[1].Organizational_Architecture.data.Orgainizational_Structure.data.List1}</li>
                  <li>{Hc.HC.data[1].Organizational_Architecture.data.Orgainizational_Structure.data.List2}</li>
                  <li>{Hc.HC.data[1].Organizational_Architecture.data.Orgainizational_Structure.data.List3}</li>
                </ul>
              </p>
              <p>
                <strong>{Hc.HC.data[1].Organizational_Architecture.data.Orgainizational_Structure.data.data.title}</strong>
                {Hc.HC.data[1].Organizational_Architecture.data.Orgainizational_Structure.data.data.text}
                <ul>
                  {Hc.HC.data[1].Organizational_Architecture.data.Orgainizational_Structure.data.data.data.map((elem) => {
                    return <li>{elem}</li>
                  })}
                </ul>
              </p>
            </div>
          </div>
          <div className={classes.detailCard}>
            <div className={classes.imgDiv}>
              <img src={image3} alt="" />
            </div>
            <div className={classes.contentDiv}>
              <h3>{Hc.HC.data[2].PerformanceManagementSystem.title}</h3>
              <p>
                {Hc.HC.data[2].PerformanceManagementSystem.text}
              </p>
              <p>
                {Hc.HC.data[2].PerformanceManagementSystem.data.text2}
              </p>
              <Accordion
                expanded={expanded === "panel4"}
                onChange={handleChange("panel4")}
              >
                <AccordionSummary
                  aria-controls="panel4-content"
                  id="panel4-header"
                >
                  <h6>1. {Hc.HC.data[2].PerformanceManagementSystem.data.content2.title1}</h6>
                </AccordionSummary>
                <AccordionDetails>
                  <p>
                  {Hc.HC.data[2].PerformanceManagementSystem.data.content2.text1}
                  </p>
                </AccordionDetails>
              </Accordion>
              <Accordion
                expanded={expanded === "panel5"}
                onChange={handleChange("panel5")}
              >
                <AccordionSummary
                  aria-controls="panel5-content"
                  id="panel5-header"
                >
                  <h6>2.  {Hc.HC.data[2].PerformanceManagementSystem.data.content2.title2}</h6>
                </AccordionSummary>
                <AccordionDetails>
                  <p>
                  {Hc.HC.data[2].PerformanceManagementSystem.data.content2.text2}
                  </p>
                  <p>
                  {Hc.HC.data[2].PerformanceManagementSystem.data.content2.content.text}
                    <ul>
                    {Hc.HC.data[2].PerformanceManagementSystem.data.content2.content.data.map((elem)=>{
                      return <li>{elem}</li>
                    })}
                    </ul>
                  </p>
                </AccordionDetails>
              </Accordion>

              <br />

              <p>
              {Hc.HC.data[2].PerformanceManagementSystem.data.text}
                <ul>
                {Hc.HC.data[2].PerformanceManagementSystem.data.content.map((elem)=>{
                  return <li>{elem}</li>
                })}

                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HCTMContent;

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: "none",
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<KeyboardArrowDownIcon sx={{ fontSize: "1.3rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "white" : "white",
  border: "none",
  borderBottom: "1px solid var(--color-primary)",
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
  color: "#6b6b6b",
}));
