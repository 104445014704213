import React, { useState } from "react";
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const responsive = [
  {
    breakpoint: 1024,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 768,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
    },
  },
  {
    breakpoint: 480,
    settings: {
      slidesToShow: 2,
      slidesToScroll: 1,
    },
  },
];

const settings = {
  responsive: responsive,
  initialSlide: 1,
  infinite: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: true,
  speed: 8000, //scroll speed
  autoplaySpeed: 0, //when to start autoPlay; 0 means instantly
  cssEase: "linear",
  pauseOnHover: false,
  adaptiveHeight: true,
  arrows: false,
};

function PartnersSlider({ logos }) {
  return (
    <>
      <Slider {...settings}>
        {logos.map((logo, index) => (
          <div key={index} style={{ backgroundColor: "white" }}>
            <img style={{ width: "150px" }} src={logo} alt="company-logo" />
          </div>
        ))}
      </Slider>
    </>
  );
}

export default PartnersSlider;
